  @import '@fontsource/roboto';

  a {
    text-decoration: none;
    color: white !important;
  }
  
  .hand {
    cursor: pointer;
  }

  .home_page_container {
    background: '#00000';
    max-width: 1920px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
  }


  .logo_icon {
    width: 300px;
  }

  /* Animate */
  /* ================== */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .animate_fade {
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-name: fadeIn;
  }

  /* ----------------------------------- */



  .header {
    height: 40px;
    background: rgba(80, 196, 51, 0.21);
    border-radius: 0px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: end;
  }


  .home_text_heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 123.19%;
    display: flex;
    align-items: center;
    color: #faf4f4;
  }


  .MobileCss {
    width: 66%;
    margin-top: -60%;
    margin-left: 40%;
  }

  .diamond_text {
    display: flex;
    align-items: center;
    left: 384px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: #FFFFFF;
    padding-left: 50px;
  }

  .diamond_points {
    width: 33px;
    left: 312px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    text-align: center;
    color: #000000;
    margin-left: 15px;
  }

  .strip_icon {
    width: 15%;
  }

  .header_icon {
    width: auto;
  }

  .img_main {
    position: absolute;
    left: 31.5%;
    top: 10%;
    width: 70%;
  }



  /* // header section updated css  */
  .header_img {
    width: 750px;
    right: 0px;
    position: absolute;
    top: 60px;
    z-index: 0;
  }

  .header_strip {
    border-radius: 50px;
    background-color: #37B236;
    padding: 10px;
    margin-top: 15px;
    width: 700px;
    display: flex;
  }

  .header_strip_icon {
    width: 70px;
  }

  .header_strip_2__ml {
    margin-left: 75px;
  }

  .header_strip_3__ml {
    margin-left: 150px;
  }

  .h_strip {
    margin-left: 100px;
    margin-top: 120px;
  }

  .header_btn {
    background: rgba(89, 84, 84, 0.34);
    width: 415px;
  }

  .background-image_header {
    background: url(assets/GreenEclipse_bg.png);    
    background-position: 100% 90%;
    background-repeat: no-repeat;
    background-size: cover;    
  }

  

  @media screen and (min-width: 360px) and (max-width: 767.9px) {
    .home_text_heading {
      font-size: 30px;
    }

    .header_img {
      display: none;
    }

    .diamond_text {
      font-size: 20px;
      padding-left: 30px;
    }

    .diamond_points {
      font-size: 30px;
    }

    .header_btn {
      width: 336px;
    }

    .header_icon {
      width: 160px;
    }

    .header_strip {
      padding: 2px;
      margin-top: 10px;
      width: 100% !important;
    }

    .h_strip {
      margin-top: 60px;
      margin-left: 0px;
    }


    .header_strip_2__ml {
      margin-left: 0px;
    }

    .header_strip_3__ml {
      margin-left: 0px;
    }

    .header {
      font-size: 12px;
      justify-content: center;
    }

    .logo_icon {
      width: 200px;
    }
  }

  /* @media screen and (min-width: 360px) and (max-width: 374px) {}

  @media screen and (min-width: 375px) and (max-width: 413px) {}

  @media screen and (min-width: 414px) and (max-width: 479px) {}*/

  @media screen and (min-width: 480px) and (max-width: 767px) {
    .home_text_heading {
      font-size: 30px;
  }
  } 

  @media screen and (min-width: 768px) and (max-width: 1023.9px) {

    .home_text_heading {
      font-size: 34px;
  }

    .header_img {
      display: none;
    }

    .h_strip {
      margin-left: 0px;
    }

    .header_strip {
      width: 100% !important;
    }


    .header_strip_2__ml {
      margin-left: 0px;
    }

    .header_strip_3__ml {
      margin-left: 0px;
    }
  }
  
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    .h_strip {
      margin-top: 75px;
      margin-left: 0px;
    }

    .header_strip_2__ml {
      margin-left: 50px;
    }

    .header_strip_3__ml {
      margin-left: 100px;
    }

    .header_strip {
      width: 560px;
    }

    .header_btn {
      max-width: 230px;
    }
  } 

  @media screen and (min-width: 1160px) and (max-width: 1279px) {
    .h_strip {
      margin-left: 75px;
    }
    .header_strip {
      width: 600px;
    }
  }

  @media screen and (min-width: 1280px) and (max-width: 1365px) {
    .header_btn {
      width: 320px;
    }

    .header_icon {
      width: 150px;
    }

    .header_strip {
      width: 650px;
    }
  }

  @media screen and (min-width: 1366px) and (max-width: 1600px) {
    @media screen and (min-width: 1400px) {
      .h_strip {
        margin-left: 260px;
      }
    }
  }

  @media screen and (min-width: 1600px) and (max-width: 1920px) {
    .h_strip {
      margin-left: 345px;
    }
  }

  @media screen and (min-width: 1680px) and (max-width: 1920px) {
    .h_strip {
      margin-left: 500px;
    }

    .header_strip {
      width: 750px;
    }
  }

  @media screen and (min-width: 1860px) and (max-width: 1920px) {
    .h_strip {
      margin-left: 540px;
    }
    .header_strip {
      width: 750px;
    }
  }

  @media screen and (min-width: 1920px) {
    .h_strip {
      margin-left: 600px;
    }

    .Strip_css {
      width: 60%;
    }
  }