
.popup-main-div{
    display: flex;
    align-items: center;
    top: 50vh;
    position: relative;
    justify-content: center;
}

.popup-overlay {
    background: rgba(0, 0, 0, .9);
    box-sizing: border-box;
    cursor: wait;
    height: 100%;
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99998
}

.popup {
    background: #fff;
    border-radius: 0;
    box-shadow: 0 0 15px #000;
    box-sizing: border-box;
    opacity: 0;
    position: fixed;
    padding: 36px;
    text-align: center;
    width: 420px;
    z-index: 99999
}

.popup h2 {
    color: #595A5B;
    font-family: "Roboto Condensed", Sans-serif;    
    font-size: 20px;
    font-weight: 500;
    text-transform: none;
    font-style: italic;
    line-height: 1.2em;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    margin: 0 0 12px 0;
    padding-bottom: 12px
}


.popup p {
    box-sizing: border-box;
    margin: 0;
    color: #959595;
    line-height: 20px
    
}

.popup p strong {
    color: #ff1f1f
}

.popup button {
    background: #ddd;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 0;
    margin-left: 1%;
    margin-top: 12px;
    padding: 6px 10px 6px 10px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 49%
}

.popup button:hover {
    background: #dadada;
    box-sizing: border-box;
    border: 1px solid transparent
}

.popup button.yes {
    background: #8eb908;
    margin-left: 0;
    margin-right: 1%
}

.popup button.yes:hover {
    background: #82a711;
    box-sizing: border-box
}

@media (max-width: 500px) {
    .popup {
        box-sizing: border-box;
        margin-left: 10px;
        margin-right: 10px;
        width: auto
    }

    .popup button {
        box-sizing: border-box;
        display: block;
        width: 100%
    }

    .popup button.no,
    .popup button.yes {
        margin-left: 0;
        margin-right: 0
    }
}