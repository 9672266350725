@import '@fontsource/roboto';

.background_img {
    background: url(../../assets//withoutMobile.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
}

.background-image {
    background: url(../../assets/bg.png);
    height: 800px;
    background-position: 100% 71%;
    background-repeat: no-repeat;
    background-size: cover;
}

.content_bg {
    background: url(../../assets/content_box.svg);
    height: 80vh;
    background-repeat: no-repeat;
    background-size: 100% 50%;
    background-position: 100% 20%;
}

.store_icon {
    width: 180px;
    height: 60px;
}


.content_box_heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 38px;
    line-height: 123.19%;
    display: flex;
    align-items: center;    
    color: #FFFFFF;
    position: absolute;
    margin-top: 75px;
}

.about_box_heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    text-align: left;    
    color: #FFFFFF;
}

.content_box_text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;    
    color: #FFFFFF;
}


.content_box_text > 
 .table {
    color: white !important;
   
    }

 ol, li, ul {
    list-style: none;
    margin-left: 0px !important;
    padding-left: 0px;
 }   
 h1,h2,h3,h4,h5,h6 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #14F45E;
 }

 .calc-run-text th {
  font-weight: 400!important;  
  
 }
 .calc-run-text td {
 
    text-align: center;

    
   }
  

.calc-run-text
{
    font-family: 'Roboto';
font-style: normal;

font-weight: 400!important;
font-size: 18px;
line-height: 21px;
color: #FFFFFF;
border-color: transparent;
}
.blue-font{
    font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 26px;
font-weight: 400!important;
color: #00C4FE;

}
.green-font{
    
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 38px;
/* font-weight: 400!important; */
color: #14F45E;
}
.trapezoid_right {
    border-bottom: 50px solid #212529;
    border-left: 55px solid transparent;
    height: 0;
    width: 70%;
    margin-left: 30%;
}

.trapezoid_left {
    border-top: 50px solid #212529;
    border-right: 55px solid transparent;
    height: 0;
    width: 70%;
    margin-right: 30%;
}

.batsman {
    top: 10%;
    position: absolute;
    width: fit-content;
    left: 50%;
}


.content_container {
    max-width: 1920px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding: 7%;
}


.content_box_green_heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #14F45E;
}

.content_box_green_heading_icon {
    width: 18.39px;
    height: 18.78px;
    margin-right: 5px;
}


.contact_content {
    margin-left: 40px;
    line-height: 20px
}

.contact_heading {
    padding-top: 80px;
    padding-left: 80px;
}

.how_to_play_text_container{
    display: flex;

}




@media screen and (max-width: 720px) {

    .background-image {
        background: url(../../assets/bg.png);
        height: 100vh;
        background-position: 100% 90%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .content_box_heading {
        align-items: center;
        position: inherit;
        margin-top: 45px;
    }

    .content_box_text {       
        font-size: 14px;            
    }

    .store_icon {
        width: 110px;
        height: 35px;
    }

    .contact_content {
        margin-left: 0px;
    }

    .contact_heading {        
        padding-left: 40px;
    }

    .content_box_green_heading{
        font-size: 16px;
    }
    
}




@media screen and (max-width: 1020px) {
    .content_box_heading {
        align-items: center;
        position: inherit;
        margin-top: 45px;
    }
}


@media screen and (min-width: 1600px) {

    .background-image {
        background: url(../../assets/bg.png);
        height: 930px;
        background-position: 100% 71%;
        background-repeat: no-repeat;
        background-size: cover;
    }
}