@import '@fontsource/roboto';


.img_ {
    width: 200px;
    position: relative;
    top: -140px;
}


.section1_text_heading {
    position: relative;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #00C4FE;
    top: -100px;
    height: 28px;
}

.section1_text {
    position: relative;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    top: -90px;
    height: 58px;
    width: 261px;
}


.rect {
    background: #303030ba;
    border-radius: 20px;
    height: 296px;
    margin-top: 350px;
}


.section1_main_heading {
    position: relative;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 56px;
    text-align: center;
    color: #FFFFFF;
    top: -190px;
}


.section_2_container {
    margin-top: 170px;
}

.section2_heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 59px;
    color: #50C433;
}


.section2_text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #FFFFFF;
}

.dots_bg {
    background: url(/src/assets/home_section_2/dots.png);
    background-repeat: no-repeat;
    background-size: 80% 70%;
}

.section2_img {
    position: relative;
    top: -185px;
    /* left: 50px; */
}


.section_3_container {
    margin-top: -100px;
}

.section3_img {
    width: 350px;
    padding: 35px;
}

.section3_heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    /* text-align: right; */
    color: #FFFFFF;
}

.section3_text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    /* text-align: right; */
    color: #FFFFFF;
}

.section3_row {
    margin-top: -60px;
}

.section3_main_heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 56px;
    text-align: center;
    color: #FFFFFF;
}

.section3_margin {
    margin-top: -60px;
}





@media screen and (max-width: 720px) {

    .section_3_container {
        margin-top: 99px;
        margin-bottom: 150px;
    }

    .img_ {
        width: 200px;
        position: inherit;
    }

    .section1_text_heading {
        position: inherit;
        margin-top: 15px;
    }

    .section1_text {
        position: inherit;
        margin-bottom: 15px;
    }

    .section_3_container {
        margin-top: 150px;
    }

    .section2_img {
        position: inherit;
    }

    .section3_margin {
        margin-top: 30px;
    }

    .section3_heading {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
    }

    .section3_text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
    }

    .rect {
        height: auto;
    }

}


@media screen and (min-width: 720px) {

    .section1_text {
        top: -70px;
    }

}


@media screen and (max-width: 768px) {
    .section1_text_heading {
        position: inherit;
        margin-top: 15px;
    }

    .section1_text {
        position: inherit;
        margin-bottom: 15px;
        margin-top: 25px;
    }

    .img_ {
        position: inherit;
        top: 0;
    }

    .rect {
        height: auto;
    }
}


@media screen and (min-width: 768px) and (max-width: 992px) {

    .section2_heading {
        font-size: 32px;
        line-height: 42px;
    }

    .section2_text {
        font-size: 14px;
    }

    .section2_img {
        top: -125px;
    }

    .section_3_container {
        margin-top: 0px;
    }
}




@media screen and (min-width: 360px) and (max-width: 767px) {
    .section1_main_heading {
        top: -130px;
        font-size: 32px;
    }

    .dots_bg {
        background: none;
    }

    .section1_text {
        margin-top: 30px;
        margin-bottom: 48px;
    }

    .section1_text_heading {
        margin-top: 30px;
    }

    .section2_img {
        position: inherit;
        margin-top: 10px;
    }

    .section_2_container {
        margin-top: 100px;
    }

    .section2_heading {
        font-size: 32px;
        line-height: 45px;
    }

    .section2_text {
        font-size: 14px;
    }

    .section_3_container {
        margin-top: 70px;
        margin-bottom: 110px;
    }

    .section3_img {
        padding: 15px;
    }

    .rect {
        margin-top: 180px !important;
    }



}



@media screen and (min-width: 360px) and (max-width: 374px) {}

@media screen and (min-width: 375px) and (max-width: 413px) {}

@media screen and (min-width: 414px) and (max-width: 479px) {}

@media screen and (min-width: 480px) and (max-width: 767px) {}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .dots_bg {
        background: none;
    }

    .rect {
        padding-bottom: 60px;
    }

    .section2_heading {
        font-size: 32px;
        line-height: 42px;
    }

    .section2_text {
        font-size: 14px;
    }

    .section2_img {
        top: -125px;
    }

    .section_3_container {
        margin-top: 0px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .dots_bg {
        background-size: 80% 70%;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1365px) {}

@media screen and (min-width: 1366) and (max-width: 1599px) {}

@media screen and (min-width: 1600px) and (max-width: 1920px) {}

@media screen and (min-width: 1920px) {}