@import '@fontsource/roboto';

.curve {
    background: #46464670 !important;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    margin-top: 10% !important;
}

.footer_logo {
    display: flex;
    justify-content: center;
    top: -50px;
    position: relative;
}

.link_ {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
}

.footer_heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 26px;
    line-height: 38px;
    text-align: left;
    color: #FFFFFF;
}

.footer_bottom_img {
    padding: 1px;
}

.footer_logo_img {
    width: 300px;
}

.dot {
    height: 40px;
    width: 40px;
    background-color: #565656;
    border-radius: 50%;
    display: inline-block;
    margin-right: 25px;
    align-items: center;
}


.icon {
    font-size: 16px;
    text-align: center;
    width: 40px;
    margin-top: 4px;
}

.footer_bar {
    background: #3AAF44 !important;
    height: 45px;
    text-align: center;
    color: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 195.69%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}


@media screen and (min-width: 360px) and (max-width: 767px) {

    .footer_heading {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
    }

    .footer_bar {
        font-size: 12px;
    }

    .link_ {
        font-size: 16px;
    }


    .footer_logo {
        position: inherit;
        padding-bottom: 30px;
        padding-top: 10px;
    }

    .footer_bottom_img {
        padding: 10px;
    }

    .curve {
        border-top-left-radius: 0%;
        height: auto;
        border-top-right-radius: 0%;
    }

    .footer_logo_img {
        width: 250px;
    }

    .icon {
        margin-top: 3px;
    }

}